<template>
  <div>
    <noData v-if="luck && luck.length == 0"></noData>
    <div
      v-else
      class="van-cell van-cell--center"
      v-for="item in luck"
      :key="item.id"
    >
      <div class="van-cell__title">
        <span>抽奖记录</span>
        <div class="van-cell__label">
          <div class="time">
            {{ item.create_time | time }}
          </div>
          <div class="time" v-if="item.prize_info.prize_type == 3">
            {{ item.prize_info.prize_config.goods_name }}
          </div>
          <div class="time" v-if="item.prize_info.prize_type == 1">
            {{ item.prize_info.prize_config.point }}积分
          </div>
          <div class="time" v-if="item.prize_info.prize_type == 2">
            {{ item.prize_info.prize_config.vouchertemplate_price }}元优惠券
          </div>
        </div>
      </div>
      <div class="van-cell__value">
        <span
          :class="item.is_send > 0 ? 'number' : 'diture'"
          v-if="item.is_send > 0"
          >{{ item.is_send > 0 ? "已发放" : "未发放" }}</span
        >
        <span
          :class="item.is_send > 0 ? 'number' : 'diture'"
          @click="goSend(item.id)"
          v-else
          >{{ item.is_send > 0 ? "已发放" : "随单发" }}</span
        >
      </div>
    </div>

    <van-popup
      v-model="addressShow"
      :style="{ height: '90%' }"
      position="bottom"
      round
    >
      <van-cell
        @click="LuckBookAddress(item.address_id)"
        v-for="item in addressList"
        :key="item.address_id"
        center
        :title="item.address_realname"
        value="登记该地址为收货地址"
        :label="`${item.area_info} ${item.address_mob_phone}`"
      />
    </van-popup>
  </div>
</template>

<script>
import { Cell, CellGroup, Popup, Toast } from "vant";
import { post } from "@get/http";
import noData from "@/components/noData/";
import oAddress from "../address/index.vue";
import { getAddressList } from "@view/me/view/address/";
export default {
  components: {
    vanCell: Cell,
    vanCellGroup: CellGroup,
    oAddress,
    vanPopup: Popup,
    noData,
  },
  data() {
    return {
      luck: [],
      addressShow: false,
      addressList: [],
      logId: 0,
    };
  },
  created() {
    this.getData();
    this.getAddressList();
  },
  methods: {
    async LuckBookAddress(address_id) {
      let res = await post(
        "/MemberLottery/win_check_in",
        {
          data: {
            log_id: this.logId,
            address_id,
          },
        },
        {
          result: true,
          resultKey: "code",
        }
      );
      if (res.code == 10000) {
        Toast(res.message);
      } else {
        Toast(res.message + "，可能已经登记过了");
      }
    },
    closePopup() {},
    closePopup(val) {
      this.addressShow = false;
    },
    goSend(id) {
      console.log(
        this.$router.push({
          name: "HomeIndex",
        })
      );
      // this.logId = id;
      // this.addressShow = true;
    },
    async getAddressList() {
      let { address_list } = await getAddressList();
      this.addressList = address_list;
    },
    async getData() {
      let { data } = await post("/MemberLottery/win_log");
      this.luck = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.number {
  color: #e93b3d;
}
</style>
